<template>
  <div class="update-transfer-stock">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Thông tin phiếu chuyển kho (phiếu tổng)'">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-form-group class="col-3">
            <label class="font-weight-bold">Mã phiếu:</label>
            <p>{{ stockInfo.code }}</p>
          </b-form-group>
          <b-form-group class="col-3">
            <label class="font-weight-bold">Ngày tạo:</label>
            <p>{{ stockInfo.importDate }}</p>
          </b-form-group>
          <b-form-group class="col-3">
            <label class="font-weight-bold">Người tạo: </label>
            <p>{{ stockInfo.createdByName }}</p>
          </b-form-group>
        </b-row>
        <hr
          class="hr-text font-weight-bold"
          data-content="Danh sách phiếu con"
        />
        <b-table
          class="myTable"
          caption-top
          responsive
          bordered
          hover
          :fields="fieldStoreTransfers"
          :items="listTransferStock"
          id="my-table"
          :per-page="perPage"
          :current-page="currentPageTransferStock"
          :busy="isBusy"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>
          <template #cell(index)="data">
            {{ ++data.index }}
          </template>
          <template #cell(storeWarehouseName)="data">
            <span class="font-weight-bold">{{ data.item.storeWarehouseName }}</span>
          </template>
          <template #cell(destinationWarehouseName)="data">
            <span class="font-weight-bold">{{ data.item.destinationWarehouseName }}</span>
          </template>
          <template #cell(code)="data">
            <div
              class="cursor-poiter"
              @click="viewDetailPage(data.item.id)"
            >
              <u>{{ data.item.code }}</u>
            </div>
          </template>
          <template #cell(requireStockCode)="data">
            <div
              class="cursor-poiter"
              @click="viewRequirePage(data.item.requireStockCode)"
            >
              <u>{{ data.item.requireStockCode }}</u>
            </div>
          </template>
          <template #cell(productQuantity)="data">
            <span>{{ data.item.stockSlipOriginalDetails?.length || 0}}</span>
          </template>
          <template v-slot:cell(details)="data">
            <i
              class="flaticon-eye"
              @click="showModalDetail(data.item, 'original')"
              role="button"
            ></i>
          </template>
          <template v-slot:cell(actualQuantity)="data">
            <div
              v-if="isStockSlipConfirmed(data.item.status)"
              class="cursor-poiter text-right"
              @click="showModalDetail(data.item, 'actual')"
            ><b-link>{{ data.item.actualQuantity }}</b-link></div>
            <div
              v-else
              class="text-right"
              v-b-tooltip.hover.right="'Phiếu chưa được xác nhận'"
            >{{ data.item.actualQuantity }}</div>
          </template>
        </b-table>
        <b-col class="row mt-3">
          <b>
            Tổng số:
            {{ listTransferStock?.length || 0 }}
          </b>
        </b-col>
        <b-pagination
          class="custom-pagination"
          v-show="listTransferStock?.length > perPage"
          v-model="currentPageTransferStock"
          :total-rows="listTransferStock.length"
          :per-page="perPage"
          align="right"
        ></b-pagination>
        <b-row>
          <b-modal
            v-b-modal.modal-xl
            ref="modal-transfer-stock-detail"
            hide-footer
            :title="'Danh sách sản phẩm trong phiếu chuyển kho ' + storeWarehouseName + ' >> ' + destinationWarehouseName"
            id="modal-transfer-stock-detail"
            size="xl"
          >
            <b-table
              bordered
              hover
              :items="transferDetail"
              :fields="fieldTransferDetailComputed"
              :per-page="perPage"
              :current-page="currentPageDetail"
            >
              <template #cell(index)="row">
                {{ row.item.index }}
              </template>
              <template #cell(productName)="row">
                <span
                  v-b-tooltip.hover.right="'Bar code'"
                  style="font-size: 11px; font-weight: 700"
                >{{ row.item.barCode }}</span><br />
                <span
                  v-b-tooltip.hover.right="'Product code'"
                  style="font-size: 12px"
                >{{ row.item.productCode }}</span><br />
                <span v-b-tooltip.hover.right="'Product name'">{{ row.item.productName }}</span>
              </template>
              <template #cell(unitPrice)="row">
                {{ convertPrice(row.item.unitPrice) }}
              </template>
              <template #cell(productImei)="row">
                <div
                  v-for="(imei, index) in row.item.productImei"
                  :key="index"
                >
                  {{ imei }},
                </div>
              </template>
              <template #cell(totalAmount)="row">
                {{ convertPrice(row.item.totalAmount) }}
              </template>
            </b-table>
            <b-row>
              <b-col fixed>
                <p
                  class="mt-3 text-dark"
                  style="font-weight: 500"
                >
                  Tổng số:
                  {{ transferDetail.length }}
                </p>
              </b-col>
              <b-pagination
                class="custom-pagination"
                v-show="transferDetail?.length > perPage"
                v-model="currentPageDetail"
                :total-rows="transferDetail?.length"
                :per-page="perPage"
                aria-controls="my-table"
                align="right"
              ></b-pagination>
            </b-row>
            <b-button
              style="width: 80px"
              variant="secondary"
              size="sm"
              @click="hideModalDetail()"
            >
              <strong>Đóng</strong>
            </b-button>
          </b-modal>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>
  
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';

.update-transfer-stock {
  .title-center {
    text-align: center;
  }

  .autosuggest__results ul {
    list-style: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  .autosuggest__results {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    border: 1px solid #e0e0e0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(1.35em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.28rem;
  }

  .autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }

  .productCode:hover {
    text-decoration: underline;
  }

  .cursor-poiter {
    cursor: pointer;
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import localData from '@/utils/saveDataToLocal';
import { convertPrice } from '@/utils/common';
import { STOCK_SLIP_STATUS } from '@/utils/enum';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';

export default {
  mixins: [validationMixin],
  data() {
    return {
      stockInfo: {},
      isBusy: false,
      storeWarehouseName: '',
      destinationWarehouseName: '',
      fieldStoreTransfers: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Mã PCK',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'requireStockCode',
          label: 'Mã phiếu XNK',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'storeWarehouseName',
          label: 'Kho nguồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'destinationWarehouseName',
          label: 'Kho đích',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'productQuantity',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'requestQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'actualQuantity',
          label: 'SL Thực nhận',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-righ',
        },
        {
          key: 'importDate',
          label: 'Ngày chuyển kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-left font-weight-bold',
        },
        {
          key: 'details',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
      ],
      listTransferStock: [],
      transferDetail: [],
      fieldTransferDetail: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
          visible: true,
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-left',
          visible: true,
        },
        {
          key: 'productImei',
          label: 'Imei',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
          visible: true,
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
          visible: this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW'),
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          visible: true,
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-right',
          visible: this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW'),
        },
      ],
      currentPageDetail: 1,
      currentPageTransferStock: 1,
      perPage: 10,
    };
  },
  validations: {
    form: {
      sourceStoreSelected: { required },
      desStoreSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  created() {
    this.getStockById();
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    fieldTransferDetailComputed() {
      return this.fieldTransferDetail.filter((item) => item.visible);
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu chuyển kho', route: '/transfer-stocks' },
      { title: 'Chi tiết phiếu chuyển kho(tổng)' },
    ]);
  },
  methods: {
    convertPrice,
    isStockSlipConfirmed(status) {
      return status === STOCK_SLIP_STATUS.CONFIRMED;
    },
    async getStockById() {
      const id = this.$route.query.id;
      this.isBusy = true;
      try {
        const data = await ApiService.get(`stocks/transfer-parent-stock/${id}`);
        this.stockInfo = data.data.data || {};
        this.listTransferStock = this.stockInfo?.transferStockChild || [];
      } catch (err) {
        console.log('err::: ', err);
      } finally {
        this.isBusy = false;
      }
    },
    showModalDetail(row, type = 'original') {
      const {
        storeWarehouseName,
        stockSlipDetails,
        destinationWarehouseName,
        stockSlipOriginalDetails,
      } = row;
      this.storeWarehouseName = storeWarehouseName;
      this.destinationWarehouseName = destinationWarehouseName;
      const transferDetail =
        type === 'original' ? stockSlipOriginalDetails : stockSlipDetails;
      this.transferDetail = transferDetail?.map((item, index) => {
        return {
          ...item,
          index: ++index,
          imeis: item.imeis ? item.imeis.split(',') : '',
        };
      });
      this.$refs[`modal-transfer-stock-detail`].show();
    },
    hideModalDetail() {
      this.$refs[`modal-transfer-stock-detail`].hide();
    },
    viewRequirePage(requireStockCode) {
      const routeData = this.$router.resolve({
        name: 'list-require-stock',
        query: { code: requireStockCode },
      });
      window.open(routeData.href, '_blank');
    },
    viewDetailPage(id) {
      const routeData = this.$router.resolve({
        name: 'update-transfer-stock',
        query: { id: id },
      });
      window.open(routeData.href, '_blank');
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>